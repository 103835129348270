//=require theme.js

$(function () {
});

function autoCloseModal(id) {
    setTimeout(() => {
        $(`#${id}Modal`).modal('hide');
        $(`#${id}Modal_forms_flash`).empty();
    }, 3000);
}
